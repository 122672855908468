.AdminPage {
  min-width: 100vw;
  min-height: 100vh;
  padding: 5% 0;
  background-size: cover;
}

.AdminContainer {
  width: 80%;
  margin: auto;
}

.AdminTabs {
  margin-bottom: -1%;
  display: inline-block;
  background: #f2f2f2;
}

.AdminTab {
  width: 120px;
  height: 60px !important;
  font-size: 1rem !important;
}

.AdminPaper {
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 0 !important; 
}

.AdminTable {
  min-width: 600px;
}

.AdminTable th, td {
  font-size: 110% !important;
  padding: 8px !important;
  max-width: 300px;
}

.AdminTable th {
  font-weight: 600;
}

.AdminHead {
  color: rgba(0, 0, 0, 0.87) !important;
}

.title-cell {
  min-width: 165px;
  font-size: 125% !important;
  color: #0f0a0a !important;
}

.year-cell {
  font-size: 120% !important;
}

.miniCol {
  width: 7%;
}

.mediumCol {
  width: 20%;
}

.AdminPeriodRow {
  height: 100px !important;
}

.AdminPeriodInput {
  width: 98%;
}

.AddButton {
  width: 125px;
  margin: auto;
  margin-top: 5%;
}

.IconButtons {
  padding-left: 4px;
  display: inline-flex;
}

.IconButton {
  padding: 4px !important;
  margin-left: 10px !important;
}

@media all and (max-width: 800px) {
  .AdminContainer {
    width: 92%;
  }
}
