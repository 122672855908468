.period-form {
  width: 70vw;
  max-width: 550px;
}

.period-form-title {
  font-size: 130%;
  font-weight: 500;
  text-align: center;
  margin-top: 10%;
}
