.full-img-paper {
  border-radius: 0 !important;
  max-width: 80vw !important;
  max-height: 80vh !important;
}

.image-full-container {
	position: relative;
}

.image-full-close {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
}

.item-full-img {
	max-height: 80vh;
	max-width: 80vw;
}
