.section {
  display: inline-block;
  width: 100%;
  margin-top: 8px;
}

.dropzone {
  display: inline-flex;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
}

.dropText {
  width: 40% !important;
  height: 80% !important;
  min-height: 120px;
  max-height: 150px;
  min-width: 120px;
  max-width: 150px;
  border-radius: 0 !important;
  border: 1px solid black !important;
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
  outline: none;
}

.icon-part1 {
  height: 30px;
  width: 20px;
  border: 1px solid #666666;
}

.icon-part2 {
  height: 30px;
  width: 20px;
  border: 1px solid #333333;
  margin-top: -25px;
  margin-left: 5px;
}

.previews {
  display: inline-block;
  padding-top: 3%;
}

.previewsContainer {
  padding-top: 15px;
  margin-top: 20px;
  display: flex;
  overflow: auto;
}
  
.preview {
  display: inline-flex;
  position: relative;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
}
 
.previewInner {
  overflow: hidden;
  background: lightgray;
}

.remove {
  position: absolute;
  right: -16px;
  top: -10px;
  cursor: pointer;
}
  
.img {
  display: block;
  margin: auto;
  width: auto;
  height: 100%;
}

@media all and (max-width: 400px) {
  .dropzone {
    float: none;
    margin: 0;
  }

  .previews {
    padding-top: 0;
  }
}
